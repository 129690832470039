export const getScrollElement = el => {
  let newEl = el;
  let elTop = newEl.offsetTop - newEl.offsetHeight * 3;
  while (newEl.offsetParent) {
    newEl = newEl.offsetParent;
    elTop += newEl.offsetTop;
  }
  return elTop;
};

export const isShouldFetch = () => {
  const footerEl = document.querySelector('.footer-wrapper');
  if (footerEl) {
    const scrollElement = getScrollElement(footerEl);
    return document.documentElement.scrollTop > scrollElement;
  }
  return false;
};

export const isSectionOne = () => {
  const sectionEl = document.getElementById('section-1');
  if (sectionEl) {
    return true;
  }
  return false;
};

export const isShouldFetchReadmore = () => {
  const footerEl = document.querySelector('.loadmore');
  if (footerEl) {
    const scrollElement = getScrollElement(footerEl);
    return document.documentElement.scrollTop + 700 > scrollElement;
  }
  return false;
};

export function calculateReadTime(counter) {
  const toArray = num => num && [...`${num}`].map(elem => +elem);
  const _a = toArray(counter)[toArray(counter).length - 1] === 0 ? 0 : 1;
  const _b = counter - 1;
  const start = Math.floor((_a === 0 ? _b : counter) / 10) * 10;
  const end = Math.ceil(counter / 10) * 10;
  const first = start === 0 ? 1 : start + 1;
  const second = end === 0 ? 10 : end;
  return `${first}-${second}`;
}
