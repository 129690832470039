import { useEffect, useRef, useState } from 'react';

import parse from 'html-react-parser';
import { NextSeo } from 'next-seo';
import SEO from 'next-seo.config';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import _ from 'lodash';
import { useRouter } from 'next/router';
import { SHOW_COMMEMORATE } from 'src/constants';
import { IMAGE_DEFAULT, WEB_DESC, WEB_KEYWORD, WEB_NAME, WEB_TITLE, WEB_URL } from 'src/constants/layout';
import { decryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { convertDatasets } from 'src/utils/datasets';
import { isShouldFetch } from 'src/utils/detect-scrolling';
import { useDesktop } from 'src/utils/device';
import { convertObjPath, dropSomeField } from 'src/utils/helper';
import { GET } from 'src/utils/services';
import useUser from 'src/utils/useUser';

const Layout = dynamic(import('src/components/layouts'));
const SectionLandingPage = dynamic(import('src/components/sections/section-landing-page'));
const SectionCover = dynamic(import('src/components/sections/section-event/section-cover'), { ssr: true });
const SectionOne = dynamic(import('src/components/sections/section-home/section-one'), { ssr: true });
const SectionTwo = dynamic(import('src/components/sections/section-home/section-two'));
const SectionThree = dynamic(import('src/components/sections/section-home/section-three'));
const SectionFour = dynamic(import('src/components/sections/section-home/section-four'));
const SectionFive = dynamic(import('src/components/sections/section-home/section-five'));
const SectionBookcase = dynamic(import('src/components/sections/section-event/section-bookcase'));
const DynamicAds = dynamic(import('src/utils/ads/dynamic-ads'), { ssr: false });
const SectionMember = dynamic(import('src/services/member-auth/components/section-member'), { ssr: false });
const sectionsNeedShow = ['sectionTwo', 'sectionThree', 'sectionFour', 'sectionFive'];

const PageIndex = ({ ads, articles, dataSetsAll, coverData, dataLatest, dataPTT, dataLive, dataLandingPage, dataAdsHeader, positionsAdsBillborad, sectionSevenCate, sectionSevenCateName }) => {
  const data = useUser();
  const [sessionSevId, setSessionSevId] = useState(null);
  const [sectionMember, setSectionMember] = useState({});
  useEffect(() => {
    const checkUser = async () => {
      if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
        const response = await fetch('/api/auth/metadata', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          const res = await response.json();
          localStorage.setItem('login-meta-data', res);
          setSectionMember(decryptionClient(res));
        }
      }
    };
    const loginMetaData = localStorage.getItem('login-meta-data');
    const decryptLoginMetaData = !_.isEmpty(loginMetaData) ? decryptionClient(loginMetaData) : {};
    const expireCondition = !_.isEmpty(loginMetaData) ? new Date(decryptLoginMetaData?.expire) < new Date() : true;

    if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
      setSessionSevId(decryptionClient(data?.user));
      _.isEmpty(loginMetaData) || expireCondition ? checkUser() : setSectionMember(decryptLoginMetaData);
    }
  }, [data]);
  const _article = {
    title: articles?.metaTag?.title || WEB_TITLE,
    description: articles?.metaTag?.description || WEB_DESC,
    keyword: articles?.metaTag?.keywords || WEB_KEYWORD
  };
  const router = useRouter();
  const nextSectionIndexRef = useRef(0);
  const sectionsVisibleRef = useRef([]);
  const [sectionsVisible, setSectionsVisible] = useState([]);
  const loadMoreArticles = () => {
    if (nextSectionIndexRef.current < sectionsNeedShow.length) {
      if (isShouldFetch()) {
        const currentIndex = nextSectionIndexRef.current;
        const section = sectionsNeedShow[currentIndex];

        nextSectionIndexRef.current = currentIndex + 1;
        sectionsVisibleRef.current = [...sectionsVisibleRef.current, section];
        setSectionsVisible(sectionsVisibleRef.current);

        setTimeout(() => {
          loadMoreArticles();
        }, 2000);
      } else {
        document.addEventListener('scroll', handleScroll);
      }
    }
  };
  const [adsBox, setAdsBox] = useState(true);

  const [dataSets, setDataSets] = useState({
    showSectionElection: [],
    electionThailand: {
      tagName: '',
      electionLink: '',
      tag: [],
      videoTag: [],
      videoPlaylist: [],
      dataSets: [],
      showVideo: ''
    },
    bookcase: [],
    bannerInews: [],
    energy: [],
    ptt: [],
    pttVideo: [],
    sectionEvent: [],
    spacial: [],
    sectionOlympic: [],
    dataBitkub: [],
    iframeoil: [],
    israelHamas: [],
    sectionEventPopular: [],
    sectionPTTVer2: [],
    sectionEnergyVer2: [],
    bookcaseHalf: [],
    sectionEventOne: []
  });
  const [popular, setPopular] = useState({
    dataPopular: []
  });

  const handleLightBoxClick = () => {
    setAdsBox(false);
  };

  const handleScroll = async () => {
    if (isShouldFetch()) {
      document.removeEventListener('scroll', handleScroll);
      loadMoreArticles();
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  // Dataset
  const fecthData = async () => {
    if (!_.isEmpty(dataSetsAll)) {
      // const book = convertDatasets(dataSetsAll, 'bookcase', false);
      const flattenedBook = _.flatten(convertDatasets(dataSetsAll, 'bookcase', false).reverse()).reverse().filter(el => el !== null);

      setDataSets({
        bookcase: flattenedBook || [],
        bannerInews: _.find(dataSetsAll, { topic: 'Banner inews and trainingcourse' }) || [],
        energy: _.find(dataSetsAll, { topic: 'energy' }) || [],
        ptt: _.find(dataSetsAll, { topic: 'ptt-img' }) || [],
        pttVideo: _.find(dataSetsAll, { topic: 'ptt-video' }) || [],
        sectionEvent: _.find(dataSetsAll, { topic: 'section-event' }) || [],
        spacial: _.find(dataSetsAll, { topic: 'section-spacial' }) || [],
        sectionOlympic: _.find(dataSetsAll, { topic: 'section-olympic' }) || [],

        dataBitkub: _.find(dataSetsAll, { topic: 'section-bitkub' }) || [],
        iframeoil: _.find(dataSetsAll, { topic: 'iframe-oil' }) || [],
        israelHamas: _.find(dataSetsAll, { topic: 'section-israel-hamas' }) || [],
        sectionEventPopular: _.find(dataSetsAll, { topic: 'section-event-popular' }) || [],
        sectionPTTVer2: _.find(dataSetsAll, { topic: 'section-ptt-ver2' }) || [],
        sectionEnergyVer2: _.find(dataSetsAll, { topic: 'section-energy-ver2' }) || [],
        bookcaseHalf: _.find(dataSetsAll, { topic: 'section-half-bookcase' }) || [],
        sectionEventOne: _.find(dataSetsAll, { topic: 'section-event-one' }) || []
      });
    }
  };
  const fecthDataPopular = async () => {
    // Data Popular
    const popular = await GET('/api/v1.0/categories/popular-news', false, true);
    if (!_.isEmpty(popular)) setPopular({ dataPopular: popular });
  };
  useEffect(() => {
    fecthData();
    fecthDataPopular();
  }, [router.isPreview]);

  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo
        {...SEO}
        title={_article?.title}
        description={_article?.description}
        openGraph={{
          ...SEO.openGraph,
          title: _article?.title,
          description: _article?.description
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: _article?.keyword
          }
        ]}
      />
      {/* --------------------------------- HEADER --------------------------------- */}
      <Head>
        <link rel='canonical' href={WEB_URL.WEB} />
        <meta property='twitter:title' content={_article?.title} />
        <meta property='twitter:description' content={_article?.description} />
        <meta property='twitter:image' content={IMAGE_DEFAULT} />
        {/* --------------------------------- Truehit -------------------------------- */}
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
                __th_page="home";
              `
          }}
        />
        {/* ----------------------------- ScriptBrowser ------------------------------------ */}
        {!useDesktop() && !_.isEmpty(dataAdsHeader) ? parse(`${dataAdsHeader?.header_mobile}`) : ''}
        {useDesktop() && !_.isEmpty(dataAdsHeader) ? parse(`${dataAdsHeader?.header_desktop}`) : ''}
        {/* ----------------------------- ADS : PPN ------------------------------- */}
        <script async type='text/javascript' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: 'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];'
          }}
        />

        {/* ---------------------------- SPIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                          {
                            "@context": "https://schema.org",
                            "@type": "WebSite",
                            "name": "${WEB_NAME}",
                            "url": "${WEB_URL.WEB}",
                            "description": "${_article?.description}",
                            "sameAs": [
                              "${WEB_URL.FACEBOOK}",
                              "${WEB_URL.TWITTER}",
                              "${WEB_URL.INSTAGRAM}",
                              "${WEB_URL.YOUTUBE}",
                              "${WEB_URL.LINE}"
                            ],
                            "potentialAction": {
                              "@type": "SearchAction",
                              "target": "${WEB_URL.WEB}search?q={search_term_string}",
                              "query-input": "required name=search_term_string"
                            }
                          }
                        }
                      `
          }}
        />

        {/* ---------------------------- SPIPPET : WebPage --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "name": "${WEB_NAME}",
                    "description": "${_article?.description}",
                    "publisher": {
                      "@type": "ProfilePage",
                      "name": "${WEB_NAME}"
                    }
                  }
                `
          }}
        />
      </Head>

      {/* ------------------------ First Screen Section ----------------------- */}
      <div className={`${SHOW_COMMEMORATE === 'true' ? 'back-white' : ''}`}>
        <SectionLandingPage data={dataLandingPage} />
      </div>

      {/* ------------------------ Cover Event on top only ----------------------- */}
      {!_.isEmpty(coverData) && (
        <div className={`${SHOW_COMMEMORATE === 'true' ? 'back-white' : ''}`}>
          <div className='default-cover'>
            <SectionCover data={coverData} />
          </div>
        </div>
      )}

      <Layout ads={ads} sessionSevId={sessionSevId}>
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.LIGHTBOX} data={ads} handler={() => handleLightBoxClick()} adsBox={adsBox} />}
        <SectionOne
          data={articles}
          dataLatest={dataLatest}
          dataPopular={popular?.dataPopular}
          sectionEventPopular={dataSets?.sectionEventPopular}
          controlAds={positionsAdsBillborad}
          ads={ads}
          showSectionElection={dataSets?.showSectionElection}
          sectionEventOne={dataSets?.sectionEventOne}
        />

        {!_.isEmpty(sectionMember) && (
          <SectionMember
            data={{
              user: dropSomeField(sectionMember, ['menu', 'tags', 'articlesTags', 'articlesCategories'])?.user,
              menu: sectionMember?.menu,
              tags: sectionMember?.tags,
              articlesTags: sectionMember?.articlesTags,
              articlesCategories: sectionMember?.articlesCategories
            }}
          />
        )}
        {sectionsVisible.includes('sectionTwo') && (
          <SectionTwo
            data={articles}
            sectionOlympic={dataSets?.sectionOlympic}
            spacial={dataSets?.spacial}
            dataBitkub={dataSets?.dataBitkub}
            energy={dataSets?.energy}
            dataPTT={dataPTT}
            sectionPTTVer2={dataSets?.sectionPTTVer2}
            ptt={dataSets?.ptt?.dataSet}
            pttVideo={dataSets?.pttVideo?.dataSet}
            sectionEvent={dataSets?.sectionEvent?.dataSet}
            israelHamas={dataSets?.israelHamas}
            sectionSevenCate={sectionSevenCate}
            sectionSevenCateName={sectionSevenCateName}
            ads={ads}
            iframeoil={dataSets?.iframeoil}
            sectionEnergyVer2={dataSets?.sectionEnergyVer2}
          />
        )}
        {sectionsVisible.includes('sectionThree') && <SectionThree ads={ads} />}
        {sectionsVisible.includes('sectionFour') &&
          <>
            <SectionFour />
            <SectionBookcase
              bookcase={dataSets?.bookcase}
              //! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                section: 'section-spacial',
                position: 'ใต้:section-10'
              }}
            />
          </>
        }
        {sectionsVisible.includes('sectionFive') &&
        <SectionFive
          bannerInews={dataSets?.bannerInews}
          dataLive={dataLive}
          bookcaseHalf={dataSets?.bookcaseHalf} />}
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />}

        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />}
      </Layout>
    </>
  );
};

export async function getServerSideProps(ctx) {
  const initProps = {
    statusCode: 200,
    errMessage: null,
    articles: null,
    coverData: [],
    dataSetsAll: null,
    dataLatest: null,
    dataPopular: null,
    bookcase: [],
    bannerInews: [],
    energy: [],
    ptt: [],
    dataCelebrate: null,
    dataPTT: null,
    sectionPTTVer2: null,
    dataLive: [],
    pttVideo: [],
    sectionEvent: [],
    spacial: [],
    iframeoil: [],
    dataLandingPage: [],
    ads: [],
    israelHamas: [],
    dataAdsHeader: null,
    dataBitkub: [],
    positionsAdsBillborad: [],
    electionThailand: {
      tagName: '',
      electionLink: '',
      tag: [],
      videoTag: [],
      dataSets: [],
      showVideo: ''
    },
    sectionEventPopular: [],
    sectionEnergyVer2: null,
    showSectionElection: [],
    sectionSevenCate: null,
    sectionSevenCateName: ''

  };
  try {
    const payload = {
      block1: 8,
      block2: 8,
      block3: 8,
      headerLatestNews: 5
    };
    const sectionOne = await GET(`/api/v1.0/home/section-one${convertObjPath(payload)}`, false, true);
    const scriptBrowser = await GET('/api/headerAds');
    if (!_.isEmpty(scriptBrowser)) {
      initProps.dataAdsHeader = scriptBrowser || '';
    }
    if (!_.isEmpty(sectionOne)) {
      initProps.ads = sectionOne?.ads || [];
      initProps.articles = sectionOne || [];
    }
    // Data Image Cover
    const cover = await GET('/api/v1.0/imagecover');
    if (!_.isEmpty(cover)) initProps.coverData = cover;

    // Data Landing Page
    const landinPage = await GET('/api/landing-page');
    if (!_.isEmpty(landinPage)) initProps.dataLandingPage = landinPage;

    // Data latest news by section
    const latest = await GET('/api/v1.0/categories/latest-news-by-one', false, true);
    if (!_.isEmpty(latest)) initProps.dataLatest = latest;

    const dataSetsAll = await GET(`/api/datasets${convertObjPath({
      limit: 50
    })}`);
    if (!_.isEmpty(dataSetsAll)) {
      const controlAdsBillborad = _.find(dataSetsAll, { topic: 'control-ads-billborad' })?.dataSet || [];
      initProps.positionsAdsBillborad = convertDatasets(controlAdsBillborad, 'positions', true) || '';
      initProps.dataSetsAll = dataSetsAll;
      const sectionSeven = _.find(dataSetsAll, { topic: 'section-7-right' })?.dataSet || [];
      if (!_.isEmpty(sectionSeven)) {
        const category = convertDatasets(sectionSeven, 'category', true);
        const resCate = await GET(`/api/v1.0/categories${category}`, false, true);
        if (!_.isEmpty(resCate)) {
          initProps.sectionSevenCate = resCate;
          initProps.sectionSevenCateName = category || '';
        }
      }
    }
  } catch (err) {
    console.log('==========> INDEX ERROR', err.message);
    initProps.errMessage = err.message;
    initProps.statusCode = err?.response?.status || 500;
  }

  return {
    props: initProps
  };
}

export default PageIndex;
